import { hexToRgb } from ".";
/**
 * @param hex
 * @param alpha 0 - 100
 */
function calculateRgba(hex, alpha) {
  const {
    r,
    g,
    b
  } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha / 100})`;
}
export { calculateRgba };