function commonInlineStyleGroupsCookie(_ref) {
  let {
    customCss: {
      antiAdBlocker
    }
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-cookie`,
    style: {
      marginTop: 10
    }
  };
}
export { commonInlineStyleGroupsCookie };