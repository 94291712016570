import { scaleMobile } from "../../scale";
function bannerInlineStyleBody(_ref) {
  let {
    layout: {
      type,
      bannerMaxWidth
    },
    individualLayout,
    customCss: {
      antiAdBlocker
    },
    mobile,
    individualPrivacyOpen,
    pageRequestUuid4
  } = _ref;
  const style = {
    transition: "width 500ms, max-width 500ms",
    maxWidth: scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
    margin: "auto"
  };
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-body`,
    style
  };
}
export { bannerInlineStyleBody };