import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useCallback } from "react";
import { useBanner } from "../../../contexts";
import { bannerInlineStyleBodyDottedGroupItem, bannerInlineStyleBodyDottedGroupBullet } from "../../../inlineStyles";
import { CommonCheckbox } from "../..";
const BannerDottedGroup = _ref => {
  let {
    group: {
      id,
      slug,
      name
    }
  } = _ref;
  const banner = useBanner();
  const {
    decision: {
      groupsFirstView
    },
    design: {
      fontSize
    },
    consent,
    essentialGroup
  } = banner;
  const isPro = process.env.PLUGIN_CTX === "pro";
  const isEssentialGroup = essentialGroup === slug;
  const isChecked = isEssentialGroup || !!consent.groups[id];
  const handleCheckbox = useCallback(() => isPro && groupsFirstView && !isEssentialGroup && banner.updateGroupChecked(id, !isChecked), [isPro, isEssentialGroup, banner, id, isChecked, groupsFirstView]);
  return h("span", _extends({
    key: name
  }, bannerInlineStyleBodyDottedGroupItem(banner)), h("span", null, isPro && groupsFirstView ? h(CommonCheckbox, {
    onClick: handleCheckbox,
    isChecked: isChecked,
    isDisabled: isEssentialGroup,
    fontSize: fontSize
  }) : h(Fragment, null, h("strong", bannerInlineStyleBodyDottedGroupBullet(banner), "\u25CF"), "\xA0"), h("span", {
    style: {
      verticalAlign: "middle",
      cursor: groupsFirstView ? "pointer" : undefined
    },
    onClick: handleCheckbox
  }, name)));
};
export { BannerDottedGroup };