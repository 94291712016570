import { bannerButtonsInlineStyleCommon } from ".";
function bannerButtonsInlineStyleAcceptEssentials(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  let {
    isTcf,
    decision: {
      acceptEssentials
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptEssentialsFontSize,
      acceptEssentialsBg,
      acceptEssentialsTextAlign,
      acceptEssentialsBorderColor,
      acceptEssentialsPadding,
      acceptEssentialsBorderWidth,
      acceptEssentialsFontColor,
      acceptEssentialsFontWeight,
      acceptEssentialsHoverBg,
      acceptEssentialsHoverFontColor,
      acceptEssentialsHoverBorderColor,
      // For two-button row layout use all dimensional properties from "Accept all"
      // For TCF use font size and font weight from "Accept all"
      acceptAllFontWeight,
      acceptAllFontSize,
      acceptAllPadding
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return bannerButtonsInlineStyleCommon({
    name: "accept-essentials",
    order,
    type: acceptEssentials,
    borderRadius,
    bg: acceptEssentialsBg,
    hoverBg: acceptEssentialsHoverBg,
    fontSize: isTwoButtonRowLayout || isTcf ? acceptAllFontSize : acceptEssentialsFontSize,
    textAlign: acceptEssentialsTextAlign,
    fontColor: acceptEssentialsFontColor,
    fontWeight: isTcf ? acceptAllFontWeight : acceptEssentialsFontWeight,
    hoverFontColor: acceptEssentialsHoverFontColor,
    borderWidth: acceptEssentialsBorderWidth,
    borderColor: acceptEssentialsBorderColor,
    hoverBorderColor: acceptEssentialsHoverBorderColor,
    padding: isTwoButtonRowLayout ? acceptAllPadding : acceptEssentialsPadding,
    antiAdBlocker,
    pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}
export { bannerButtonsInlineStyleAcceptEssentials };