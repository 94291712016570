import { HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, OPT_IN_CONTENT_BLOCKER_ALL } from "@devowl-wp/headless-content-unblocker";
import { regExpPatternFromWildcardName } from "..";
/**
 * `window.consentApi.unblock`: Check if a given URL / string is blocked by a Content Blocker:
 *
 * - When a Content Blocker exists, the Promise is only resolved after given consent
 * - When no Content Blocker exists, the Promise is immediate resolved
 *
 * Example (ES5):
 * ```js
 * (window.consentApi && window.consentApi.unblock("player.vimeo.com") || Promise.resolve()).then(function() {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * Example (ES6, Babel, TS):
 * ```js
 * (window.consentApi?.unblock("player.vimeo.com") || Promise.resolve()).then(() => {
 *     console.log("Consent for Vimeo given, unlock content...!");
 * });
 * ```
 *
 * @param url
 * @param ref Create a visual content blocker to a given HTML element
 */
function unblock(blocker, url) {
  let ref = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : document.createElement("div");
  return new Promise(resolve => {
    // Find matching blocker
    let found;
    const isPseudoRef = !ref.parentElement;
    loop1: for (const row of blocker) {
      const {
        rules
      } = row;
      // Check if host matches
      for (const rule of rules) {
        const regexp = regExpPatternFromWildcardName(rule);
        if (url.match(new RegExp(regexp, "s"))) {
          found = row;
          break loop1;
        }
      }
    }
    if (found) {
      // Add attributes to DOM element so it can be consumed by `manipulateDom`
      ref.setAttribute(HTML_ATTRIBUTE_BY, "services");
      ref.setAttribute(HTML_ATTRIBUTE_COOKIE_IDS, found.services.join(","));
      ref.setAttribute(HTML_ATTRIBUTE_BLOCKER_ID, found.id.toString());
      ref.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, () => {
        isPseudoRef && ref.remove();
        resolve();
      });

      // Append blocking element to HTML DOM if not already exists
      if (isPseudoRef) {
        document.body.appendChild(ref);
      }
    } else {
      resolve();
    }
  });
}
export { unblock };