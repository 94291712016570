import { scaleMobile } from "../..";
function commonInlineStyleGroupsCookieProperty(_ref) {
  let {
    group: {
      groupBorderWidth,
      groupBorderColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    // only used for banner
    pageRequestUuid4
  } = _ref;
  const useGroupBorderWidth = pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, groupBorderWidth) : groupBorderWidth;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-cookie-prop`,
    style: {
      borderLeft: useGroupBorderWidth > 0 ? `1px solid ${groupBorderColor}` : undefined,
      paddingLeft: 15
    }
  };
}
export { commonInlineStyleGroupsCookieProperty };