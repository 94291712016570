import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useState } from "react";
import { commonGroupsInlineStyleLinkMore } from "../../inlineStyles";
import { useBannerOrBlocker } from "../../hooks";
const CommonLinkMore = _ref => {
  let {
    onToggle,
    children,
    showMore,
    hideMore,
    ...rest
  } = _ref;
  const bannerOrBlocker = useBannerOrBlocker();
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  return h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h("a", _extends({
    href: "#"
  }, commonGroupsInlineStyleLinkMore(bannerOrBlocker, isHover), {
    onClick: e => {
      const newOpen = !isOpen;
      setIsOpen(newOpen);
      onToggle?.(newOpen);
      e.preventDefault();
    },
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false)
  }, rest), isOpen ? hideMore : showMore), isOpen && children);
};
export { CommonLinkMore };