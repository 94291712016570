import _extends from "@babel/runtime/helpers/extends";
import { useBanner, bannerInlineStyleHistorySelect, bannerInlineStyleHistorySelectOption } from "../../..";
import { useMemo } from "react";
import { Fragment, useState, useEffect } from "react";
const SELECT_NONE = -1;
const BannerHistorySelect = () => {
  const banner = useBanner();
  const [selectedId, setSelectedId] = useState(SELECT_NONE);
  const {
    activeAction,
    history,
    selectHistoryEntry,
    i18n: {
      historyLabel,
      historySelectNone
    }
  } = banner;
  const selectedHistoryItem = useMemo(() => history?.filter(_ref => {
    let {
      id
    } = _ref;
    return id === selectedId;
  })?.[0], [selectedId]);

  // Reset select after exit history dialog
  useEffect(() => {
    if (!activeAction) {
      setSelectedId(SELECT_NONE);
    } else if (activeAction === "history" && history?.length) {
      setSelectedId(history[0].id);
    }
  }, [activeAction, history]);

  // History can only contain my data, so we can pick the first entry for the history
  const uuid = selectedHistoryItem?.uuid;
  const optionStyle = bannerInlineStyleHistorySelectOption(banner);
  return h(Fragment, null, historyLabel, "\xA0", h("select", _extends({}, bannerInlineStyleHistorySelect(banner), {
    disabled: !history?.length,
    value: selectedId,
    onChange: e => {
      const value = +e.target.value;
      history.forEach(_ref2 => {
        let {
          id,
          decision,
          groups,
          tcf
        } = _ref2;
        if (id === value) {
          setSelectedId(id);
          selectHistoryEntry(groups, decision, tcf);
        }
      });
    }
  }), history?.length > 0 ? history.map(_ref3 => {
    let {
      id,
      isDoNotTrack,
      isUnblock,
      isForwarded,
      created
    } = _ref3;
    return h("option", _extends({
      key: id,
      value: id
    }, optionStyle), new Date(created).toLocaleString(document.documentElement.lang), isDoNotTrack ? " (Do Not Track)" : "", isUnblock ? " (Content Blocker)" : "", isForwarded ? " (Consent Forwarding)" : "");
  }) : h("option", _extends({
    value: SELECT_NONE
  }, optionStyle), historySelectNone)), !!uuid && h("div", {
    style: {
      opacity: 0.5,
      marginTop: 5
    }
  }, "UUID: ", uuid));
};
export { BannerHistorySelect };