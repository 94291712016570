import { scaleMobile } from "../../scale";
function bannerFooterInlineStyle(_ref) {
  let {
    layout: {
      type,
      bannerMaxWidth
    },
    individualLayout,
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4,
    individualPrivacyOpen
  } = _ref;
  const style = {
    transition: "width 500ms, max-width 500ms",
    maxWidth: scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
    margin: "auto",
    lineHeight: 1.8
  };
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-footer`,
    style
  };
}
export { bannerFooterInlineStyle };