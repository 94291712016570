import { hexToRgb } from "../../../utils";
import { scaleMobile } from "../../scale";
import { BUTTONS_WIDTH } from "./leftSide";
function bannerInlineStylesRightSide(_ref) {
  let {
    layout: {
      type,
      dialogMaxWidth
    },
    design: {
      borderWidth,
      bg,
      fontColor
    },
    headerDesign,
    footerDesign,
    bodyDesign: {
      padding
    },
    individualLayout: {
      inheritDialogMaxWidth
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    individualPrivacyOpen,
    pageRequestUuid4
  } = _ref;
  const useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, borderWidth);
  const usePadding = scaleMobile(mobile, pageRequestUuid4, padding, undefined, true);
  const {
    r,
    g,
    b
  } = hexToRgb(fontColor);
  const width = useType === "dialog" ? +dialogMaxWidth - usePadding[1] - usePadding[3] - useBorderWidth * 2 : BUTTONS_WIDTH;
  const style = {
    background: bg,
    paddingTop: useType === "banner" || individualPrivacyOpen ? +usePadding[0] : 10,
    paddingBottom: !individualPrivacyOpen ? +usePadding[2] : 10,
    float: useType === "banner" || individualPrivacyOpen ? "right" : undefined,
    maxWidth: width,
    width,
    margin: useType === "dialog" && individualPrivacyOpen && !inheritDialogMaxWidth ? "0 0 10px 10px" : undefined,
    // position: individualPrivacyOpen ? "sticky" : undefined, This is done by `body.tsx#usePlainCss`
    position: individualPrivacyOpen ? undefined : "sticky",
    [individualPrivacyOpen ? "top" : "bottom"]: individualPrivacyOpen ? `calc(var(--rendered-height-header) + ${scaleMobile(mobile, pageRequestUuid4, headerDesign.borderWidth)}px)` : `calc(var(--rendered-height-footer) + ${scaleMobile(mobile, pageRequestUuid4, footerDesign.borderWidth)}px)`,
    zIndex: 1,
    // Avoid overlapping the Checkbox Icon
    display: "flex",
    flexWrap: "wrap",
    transition: "box-shadow ease-in-out .1s",
    ["--boxShadowShowScroll"]: `0 -15px 15px -15px rgba(${r}, ${g}, ${b}, 0.3)`
  };
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-tb-right`,
    style
  };
}
export { bannerInlineStylesRightSide };