import { bannerButtonsInlineStyleCommon } from "./";
function bannerButtonsInlineStyleSave(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  let {
    isTcf,
    layout: {
      borderRadius
    },
    bodyDesign: {
      // For TCF use font size and font weight from "Accept all"
      acceptAllFontWeight,
      acceptAllFontSize
    },
    saveButton: {
      type,
      fontSize,
      bg,
      textAlign,
      borderColor,
      padding,
      borderWidth,
      fontColor,
      fontWeight,
      hoverBg,
      hoverFontColor,
      hoverBorderColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return bannerButtonsInlineStyleCommon({
    name: "accept-individual-save",
    order,
    type,
    borderRadius,
    bg,
    hoverBg,
    fontSize: isTcf ? acceptAllFontSize : fontSize,
    textAlign,
    fontColor,
    fontWeight: isTcf ? acceptAllFontWeight : fontWeight,
    hoverFontColor,
    borderWidth: borderWidth,
    borderColor,
    hoverBorderColor,
    padding,
    antiAdBlocker,
    pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}
export { bannerButtonsInlineStyleSave };