import { useMemo } from "react";
import { Fragment } from "react";
import { CONTEXT_LATEST_DESIGN_VERSION } from "../../../types";
import { CookieProperty } from ".";
import { useBannerOrBlocker } from "../../../hooks";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";
import { EServiceTemplateDataProcessingInCountriesSpecialTreatment } from "@devowl-wp/api-real-cookie-banner";
const CookiePropertyList = _ref => {
  let {
    cookie: {
      purpose,
      provider,
      providerPrivacyPolicyUrl,
      providerLegalNoticeUrl,
      legalBasis,
      ePrivacyUSA,
      dataProcessingInCountries,
      dataProcessingInCountriesSpecialTreatments,
      isEmbeddingOnlyExternalResources,
      technicalDefinitions,
      codeDynamics
    },
    isEssentialGroup
  } = _ref;
  const {
    i18n,
    isEPrivacyUSA,
    isDataProcessingInUnsafeCountries,
    iso3166OneAlpha2,
    dataProcessingInUnsafeCountriesSafeCountries,
    designVersion
  } = useBannerOrBlocker();
  const types = getCookieTypeLocalizationMap();
  const dataProcessingInUnsafeCountries = useMemo(() => {
    const mapCountryIso = c => iso3166OneAlpha2[c] || c;
    return isDataProcessingInUnsafeCountries ? calculateUnsafeCountries({
      dataProcessingInCountries,
      safeCountries: dataProcessingInUnsafeCountriesSafeCountries,
      specialTreatments: dataProcessingInCountriesSpecialTreatments.filter(e => [
      // Ignore the following special treatments for displaying unsecuring countries
      EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses].indexOf(e) === -1),
      designVersion
    }).map(mapCountryIso) : [];
  }, [isDataProcessingInUnsafeCountries, dataProcessingInUnsafeCountriesSafeCountries, dataProcessingInCountriesSpecialTreatments, dataProcessingInCountries, iso3166OneAlpha2, designVersion]);
  return h(Fragment, null, !!purpose && h(CookieProperty, {
    label: i18n.purpose,
    value: purpose
  }), h(CookieProperty, {
    label: i18n.legalBasis,
    value: legalBasis === "legal-requirement" ? i18n.legalRequirement : legalBasis === "legitimate-interest" || isEssentialGroup ? i18n.legitimateInterest : i18n.consent
  }), h(CookieProperty, {
    label: i18n.provider,
    value: provider
  }), !!providerPrivacyPolicyUrl && h(CookieProperty, {
    label: i18n.providerPrivacyPolicyUrl,
    value: providerPrivacyPolicyUrl
  }), !!providerLegalNoticeUrl && h(CookieProperty, {
    label: i18n.providerLegalNoticeUrl,
    value: providerLegalNoticeUrl
  }), !!isEPrivacyUSA && h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: ePrivacyUSA,
    printValueAs: "boolean"
  }), dataProcessingInUnsafeCountries.length > 0 && h(CookieProperty, {
    label: i18n.dataProcessingInUnsafeCountries,
    value: dataProcessingInUnsafeCountries.join(", ")
  }), designVersion > 4 && dataProcessingInCountriesSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses) > -1 && h(CookieProperty, {
    label: i18n.appropriateSafeguard,
    value: i18n.standardContractualClauses
  }), !isEmbeddingOnlyExternalResources && technicalDefinitions.map(_ref2 => {
    let {
      type,
      name,
      host,
      duration,
      durationUnit,
      isSessionDuration,
      purpose
    } = _ref2;
    return h(CookieProperty, {
      key: `${name}-${host}`,
      label: i18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, applyDynamicsToHtml(name, codeDynamics))
    }, h(CookieProperty, {
      label: i18n.type,
      value: types[type].name
    }), !!purpose && h(CookieProperty, {
      label: i18n.purpose,
      value: purpose
    }), !!host && h(CookieProperty, {
      label: i18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, host)
    }), h(CookieProperty, {
      label: i18n.duration,
      value: ["local", "indexedDb"].indexOf(type) > -1 ? i18n.noExpiration : isSessionDuration || type === "session" ? "Session" : `${duration} ${i18n.durationUnit[durationUnit]}`
    }));
  }));
};
function calculateUnsafeCountries(_ref3) {
  let {
    dataProcessingInCountries,
    safeCountries,
    specialTreatments = [],
    designVersion = CONTEXT_LATEST_DESIGN_VERSION
  } = _ref3;
  const useSpecialTreatments = designVersion > 4 ? specialTreatments : [];
  return dataProcessingInCountries.filter(c => useSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses) > -1 ? false : safeCountries.indexOf(c) === -1 || c === "US" && useSpecialTreatments.indexOf(EServiceTemplateDataProcessingInCountriesSpecialTreatment.ProviderIsSelfCertifiedTransAtlanticDataPrivacyFramework) === -1);
}
function getCookieTypeLocalizationMap() {
  return {
    http: {
      name: "HTTP Cookie",
      abbr: "HTTP",
      backgroundColor: "black"
    },
    local: {
      name: "Local Storage",
      abbr: "Local",
      backgroundColor: "#b3983c"
    },
    session: {
      name: "Session Storage",
      abbr: "Session",
      backgroundColor: "#3c99b3"
    },
    indexedDb: {
      name: "IndexedDB",
      abbr: "I-DB",
      backgroundColor: "#4ab33c"
    }
  };
}
export { CookiePropertyList, calculateUnsafeCountries, getCookieTypeLocalizationMap };