function commonInlineStyleBodyTeachingsSeparator(_ref) {
  let {
    layout: {
      borderRadius
    },
    bodyDesign: {
      teachingsSeparatorActive,
      teachingsSeparatorWidth,
      teachingsSeparatorHeight,
      teachingsSeparatorColor
    },
    customCss: {
      antiAdBlocker
    }
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-teachings-separator`,
    style: {
      marginTop: 7,
      display: "inline-block",
      maxWidth: "100%",
      borderRadius: +borderRadius,
      width: +teachingsSeparatorWidth,
      height: teachingsSeparatorActive ? +teachingsSeparatorHeight : 0,
      background: teachingsSeparatorColor
    }
  };
}
export { commonInlineStyleBodyTeachingsSeparator };