function bannerInlineStyleBodyDottedGroupBullet(_ref) {
  let {
    bodyDesign: {
      dottedGroupsBulletColor
    }
  } = _ref;
  return {
    className: undefined,
    style: {
      color: dottedGroupsBulletColor
    }
  };
}
export { bannerInlineStyleBodyDottedGroupBullet };