import { calculateRgba } from "../../../utils";
import { scaleMobile } from "../../scale";
function bannerInlineStyleHistorySelect(_ref) {
  let {
    design: {
      fontSize,
      fontColor
    },
    bodyDesign: {
      descriptionInheritFontSize,
      descriptionFontSize
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-history-select`,
    style: {
      fontSize: scaleMobile(mobile, pageRequestUuid4, descriptionInheritFontSize ? +fontSize : +descriptionFontSize),
      background: "transparent",
      border: 0,
      color: fontColor,
      borderBottom: `1px solid ${calculateRgba(fontColor, 50)}`
    }
  };
}
export { bannerInlineStyleHistorySelect };