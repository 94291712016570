import _extends from "@babel/runtime/helpers/extends";
import { useBanner, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER, usePlainCss, scaleMobile, bannerInlineStylesRightSide, bannerInlineStylesLeftSide, bannerInlineStyleBody, bannerInlineStyleBodyContainer, bannerInlineStyleBodyDescription, commonInlineStyleBodyTeaching, commonInlineStyleBodyTeachingsSeparator, BannerDottedGroupList, BannerTcfStackList, BannerGroupList, BannerTcfGroupList, BannerButtonList, BannerHistorySelect, useCommonTeachings } from "../../..";
import { useMemo, Fragment } from "react";
const BannerBody = _ref => {
  let {
    leftSideContainerRef,
    rightSideContainerRef
  } = _ref;
  const banner = useBanner();
  const {
    groups,
    layout: {
      type
    },
    mobile,
    bodyDesign: {
      teachingsSeparatorActive
    },
    tcf,
    individualPrivacyOpen,
    activeAction,
    pageRequestUuid4,
    individualTexts: {
      postamble
    }
  } = banner;
  const {
    description,
    teachings
  } = useCommonTeachings({
    services: groups.map(_ref2 => {
      let {
        items
      } = _ref2;
      return [...items];
    }).flat()
  });

  // Make the content responsive
  const leftSideId = `bnnr-body-leftSide-${pageRequestUuid4}`;
  const rightSideId = `bnnr-body-rightSide-${pageRequestUuid4}`;
  const contentId = `cntnt-${pageRequestUuid4}`;
  usePlainCss(` #${leftSideId} img {
  max-width: 100%;
  height: auto;
}

${document.getElementById(pageRequestUuid4)?.clientWidth > BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER ? "" : `#${leftSideId} {
    float: initial !important;
    width: auto !important;
    padding-right: initial !important;
    ${scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" && !individualPrivacyOpen ? "padding-bottom: 10px !important;" : individualPrivacyOpen ? "padding-top: 10px !important;" : ""}
    }

    #${contentId} {
        min-height: calc(var(--rendered-height-header) + var(--rendered-height-footer) + var(--rendered-height-rightSideContainer) + 60px);
    }
    
    #${rightSideId} {
    float: initial !important;
    width: auto !important;
    max-width: initial !important;
    margin: initial !important;
    ${individualPrivacyOpen ? "padding-bottom: 5px !important;" : "padding-top: 10px !important;"}
    position: sticky;
    }`}`);

  // Calculate the body content depending on TCF availability and if second view got opened
  const stackOrGroups = useMemo(() => {
    if (process.env.IS_TCF === "1" && tcf) {
      return individualPrivacyOpen ? h(BannerTcfGroupList, null) : h(BannerTcfStackList, null);
    } else {
      return individualPrivacyOpen ? h(BannerGroupList, null) : h(BannerDottedGroupList, null);
    }
  }, [individualPrivacyOpen, tcf]);
  const leftSide = h("div", _extends({
    ref: leftSideContainerRef,
    key: "leftSide",
    id: leftSideId
  }, bannerInlineStylesLeftSide(banner)), h("div", bannerInlineStyleBodyDescription(banner), activeAction === "history" ? h(BannerHistorySelect, null) : h(Fragment, null, h("span", {
    dangerouslySetInnerHTML: {
      __html: description
    }
  }), teachings.length > 0 && h(Fragment, null, teachingsSeparatorActive && h("div", null, h("span", commonInlineStyleBodyTeachingsSeparator(banner))), teachings.map(teaching => h("span", _extends({
    key: teaching
  }, commonInlineStyleBodyTeaching(banner), {
    dangerouslySetInnerHTML: {
      __html: teaching
    }
  })))))), stackOrGroups, !!postamble && individualPrivacyOpen && h("div", bannerInlineStyleBodyDescription(banner), teachingsSeparatorActive && h("div", null, h("span", commonInlineStyleBodyTeachingsSeparator(banner))), h("span", _extends({}, commonInlineStyleBodyTeaching(banner, true), {
    dangerouslySetInnerHTML: {
      __html: postamble
    }
  }))));
  const rightSide = activeAction === "history" ? h("div", {
    ref: rightSideContainerRef
  }) : h("div", _extends({
    ref: rightSideContainerRef,
    key: "rightSide",
    id: rightSideId
  }, bannerInlineStylesRightSide(banner)), h(BannerButtonList, null));
  return h("div", bannerInlineStyleBodyContainer(banner), h("div", bannerInlineStyleBody(banner), individualPrivacyOpen ? [rightSide, leftSide] : [leftSide, rightSide], h("div", {
    style: {
      clear: "both"
    }
  })));
};
export { BannerBody };