function bannerInlineStyleBodyDottedGroups(_ref) {
  let {
    customCss: {
      antiAdBlocker
    }
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-dotted-groups`,
    style: {
      marginTop: 10,
      lineBreak: "anywhere",
      lineHeight: 2
    }
  };
}
export { bannerInlineStyleBodyDottedGroups };