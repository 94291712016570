import { scaleMobile } from "../../scale";
function bannerButtonsInlineStyleCommon(_ref, mobile, isHover, framed, isTwoButtonRowLayout) {
  let {
    name,
    order,
    type,
    borderRadius,
    bg,
    hoverBg,
    fontSize,
    textAlign,
    fontColor,
    fontWeight,
    hoverFontColor,
    borderWidth,
    borderColor,
    hoverBorderColor,
    padding,
    antiAdBlocker,
    pageRequestUuid4
  } = _ref;
  const fullWidth = !isTwoButtonRowLayout || order > 1;
  const marginLeft = isTwoButtonRowLayout && order === 1 ? 5 : 0;
  const marginRight = isTwoButtonRowLayout && order === 0 ? 5 : 0;
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, +borderWidth);
  const style = {
    textDecoration: type === "link" ? "underline" : "none",
    borderRadius: +borderRadius,
    cursor: type === "button" ? "pointer" : undefined,
    backgroundColor: type === "button" ? isHover ? hoverBg : bg : undefined,
    fontSize: scaleMobile(mobile, pageRequestUuid4, +fontSize),
    fontWeight,
    color: isHover ? hoverFontColor : fontColor,
    transition: "background-color 250ms, color 250ms, border-color 250ms",
    margin: `0 ${marginRight}px ${scaleMobile(mobile, pageRequestUuid4, 10, undefined, true)}px ${marginLeft}px`,
    border: type === "button" && useBorderWidth > 0 ? `${useBorderWidth}px solid ${isHover ? hoverBorderColor : borderColor}` : undefined,
    padding: scaleMobile(mobile, pageRequestUuid4, padding, undefined, type !== "button").map(p => `${p}px`).join(" "),
    overflow: "hidden",
    outline: framed ? "rgb(255, 94, 94) solid 5px" : undefined,
    flex: fullWidth ? "1 1 100%" : `0 0 calc(50% - ${marginLeft + marginRight}px)`,
    display: "flex",
    alignItems: "center",
    // text-align is not supported within a `display: flex`
    justifyContent: textAlign === "right" ? "flex-end" : textAlign === "center" ? "center" : "left",
    textAlign,
    order
  };
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-btn-${name}`,
    style
  };
}
export { bannerButtonsInlineStyleCommon };