import { scaleMobile } from "../../scale";
function commonInlineStyleBodyTeaching(_ref, isSingle) {
  let {
    bodyDesign: {
      teachingsInheritTextAlign,
      teachingsTextAlign,
      teachingsInheritFontSize,
      teachingsFontSize,
      teachingsInheritFontColor,
      teachingsFontColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    // only given for banner
    pageRequestUuid4
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-teachings`,
    style: {
      marginTop: 7,
      marginBottom: isSingle ? 7 : 0,
      display: "inline-block",
      textAlign: teachingsInheritTextAlign ? undefined : teachingsTextAlign,
      fontSize: teachingsInheritFontSize ? undefined : pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +teachingsFontSize) : +teachingsFontSize,
      color: teachingsInheritFontColor ? undefined : teachingsFontColor
    }
  };
}
export { commonInlineStyleBodyTeaching };