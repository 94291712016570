import { scaleMobile } from "../..";
function bannerOverlayInlineStyleAlignment(_ref) {
  let {
    layout: {
      type,
      dialogPosition
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  const style = {
    display: "flex",
    width: "100%",
    height: "100%"
  };
  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog") {
    style.alignItems = dialogPosition.startsWith("top") ? "flex-start" : dialogPosition.startsWith("bottom") ? "flex-end" : "center";
    style.justifyContent = dialogPosition.endsWith("Center") ? "center" : dialogPosition.endsWith("Left") ? "flex-start" : "flex-end";
  }
  return {
    className: `wp-exclude-emoji ${antiAdBlocker === "y" ? "" : `rcb-align`}`,
    style
  };
}
export { bannerOverlayInlineStyleAlignment };