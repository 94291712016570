import { BannerDottedGroup } from ".";
import { useBanner, bannerInlineStyleBodyDottedGroups } from "../../..";
const BannerDottedGroupList = () => {
  const banner = useBanner();
  const {
    groups,
    decision: {
      showGroups
    }
  } = banner;
  return showGroups ? h("div", bannerInlineStyleBodyDottedGroups(banner), groups.filter(_ref => {
    let {
      items
    } = _ref;
    return !!items.length;
  }).map(group => h(BannerDottedGroup, {
    key: group.id,
    group: group
  }))) : null;
};
export { BannerDottedGroupList };