import { scaleMobile } from "../..";
function bannerGroupsInlineStyleGroup(_ref) {
  let {
    group: {
      groupBg,
      groupBorderRadius,
      groupBorderColor,
      groupPadding,
      groupBorderWidth,
      groupInheritBg
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  const useGroupBorderWidth = scaleMobile(mobile, pageRequestUuid4, groupBorderWidth);
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-group`,
    style: {
      background: groupInheritBg ? undefined : groupBg,
      border: useGroupBorderWidth > 0 ? `${useGroupBorderWidth}px solid ${groupBorderColor}` : undefined,
      padding: scaleMobile(mobile, pageRequestUuid4, groupPadding).map(p => `${p}px`).join(" "),
      borderRadius: +groupBorderRadius,
      textAlign: "left"
    }
  };
}
export { bannerGroupsInlineStyleGroup };