import { scaleMobile } from "../../scale";
function commonInlineStyleFooterLink(_ref) {
  let {
    footerDesign: {
      fontSize,
      fontColor,
      hoverFontColor,
      fontInheritFamily,
      fontFamily
    },
    design: {
      linkTextDecoration
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    // only used for banner
    pageRequestUuid4
  } = _ref;
  let isHover = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const style = {
    textDecoration: linkTextDecoration,
    fontSize: pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +fontSize) : +fontSize,
    color: isHover ? hoverFontColor : fontColor,
    fontFamily: fontInheritFamily ? undefined : fontFamily,
    padding: "0 5px"
  };
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-footer-link`,
    style
  };
}
export { commonInlineStyleFooterLink };