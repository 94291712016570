function commonGroupsInlineStyleLinkMore(_ref, isHover) {
  let {
    design: {
      linkTextDecoration
    },
    group: {
      linkColor,
      linkHoverColor
    },
    customCss: {
      antiAdBlocker
    }
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-group-more`,
    style: {
      color: isHover ? linkHoverColor : linkColor,
      textDecoration: linkTextDecoration
    }
  };
}
export { commonGroupsInlineStyleLinkMore };