import { scaleMobile } from "../..";
function bannerGroupsInlineStyleDescription(_ref) {
  let {
    design: {
      fontWeight
    },
    group: {
      descriptionFontColor,
      descriptionFontSize
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-group-description`,
    style: {
      color: descriptionFontColor,
      fontSize: scaleMobile(mobile, pageRequestUuid4, +descriptionFontSize),
      fontWeight,
      marginTop: 5
    }
  };
}
export { bannerGroupsInlineStyleDescription };