import { bannerButtonsInlineStyleCommon } from ".";
function bannerButtonsInlineStyleAcceptIndividual(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  let {
    decision: {
      acceptIndividual
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptIndividualFontSize,
      acceptIndividualBg,
      acceptIndividualTextAlign,
      acceptIndividualBorderColor,
      acceptIndividualPadding,
      acceptIndividualBorderWidth,
      acceptIndividualFontColor,
      acceptIndividualFontWeight,
      acceptIndividualHoverBg,
      acceptIndividualHoverFontColor,
      acceptIndividualHoverBorderColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return bannerButtonsInlineStyleCommon({
    name: "accept-individual",
    order,
    type: acceptIndividual,
    borderRadius,
    bg: acceptIndividualBg,
    hoverBg: acceptIndividualHoverBg,
    fontSize: acceptIndividualFontSize,
    textAlign: acceptIndividualTextAlign,
    fontColor: acceptIndividualFontColor,
    fontWeight: acceptIndividualFontWeight,
    hoverFontColor: acceptIndividualHoverFontColor,
    borderWidth: acceptIndividualBorderWidth,
    borderColor: acceptIndividualBorderColor,
    hoverBorderColor: acceptIndividualHoverBorderColor,
    padding: acceptIndividualPadding,
    antiAdBlocker,
    pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}
export { bannerButtonsInlineStyleAcceptIndividual };