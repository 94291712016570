import { isUrl, isHost } from ".";

/**
 * Decode HTML entities in a given string.
 *
 * @see https://stackoverflow.com/a/34064434/5506547
 */
function htmlDecode(input) {
  const inputIsUrl = isUrl(input) && !isHost(input);
  if (inputIsUrl) {
    // URLs needs to be handled a bit different as e.g. `?regionid` gets parsed to `&#39;ionid=`
    const doc = new DOMParser().parseFromString(`<a href="${input}"></a>`, "text/html");
    return doc.querySelector("a")?.href;
  } else {
    // Keep XSS protection intact
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }
}
export { htmlDecode };