import { HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE } from "../dom";

/**
 * In some cases, the complete layout can be changed and calculations are based on containers width.
 * Imagine: Google Fonts get added and the navigation bar has a button with a calculated width (e.g. BeTheme).
 * Another example: The instagram feed is not loaded / adjusted (masonry), so we need to trigger a resize
 * event manually
 *
 * @param delay
 */
function dispatchResizeEvent() {
  let delayOrFromElement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  let delay;
  if (typeof delayOrFromElement === "number") {
    delay = delayOrFromElement;
  } else if (delayOrFromElement?.hasAttribute(HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE)) {
    delay = +delayOrFromElement.getAttribute(HTML_ATTRIBUTE_CLICK_DISPATCH_RESIZE);
  } else {
    return;
  }
  setTimeout(() => {
    try {
      window.dispatchEvent(new Event("resize"));
    } catch (e) {
      // You are running in IE, it's a UI bug, we can skip...
    }
  }, delay);
}
export { dispatchResizeEvent };