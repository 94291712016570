import { scaleMobile } from "../..";
/**
 * Use `visible` together with `useLayoutEffect` to avoid flickering.
 * This is needed because the SVG icons of ant are a bit flickering.
 */
function commonInlineStyleGroupsCheckbox(_ref, visible, checked, disabled, fontSize) {
  let {
    layout: {
      borderRadius
    },
    group: {
      headlineFontSize,
      checkboxBg,
      checkboxBorderWidth,
      checkboxBorderColor,
      checkboxActiveBg,
      checkboxActiveBorderColor,
      checkboxActiveColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  let useFontSize = fontSize || headlineFontSize;
  useFontSize = pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, useFontSize) : useFontSize;
  const useCheckboxBorderWidth = pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +checkboxBorderWidth) : +checkboxBorderWidth;
  const height = +useFontSize + useCheckboxBorderWidth * 2 + 6;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-checkbox`,
    style: {
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? 0.5 : undefined,
      color: checked ? checkboxActiveColor : checkboxBg,
      display: visible ? "inline-block" : "none",
      background: checked ? checkboxActiveBg : checkboxBg,
      border: `${useCheckboxBorderWidth}px solid ${checked ? checkboxActiveBorderColor : checkboxBorderColor}`,
      padding: 3,
      height,
      width: height,
      marginRight: 10,
      borderRadius: +borderRadius,
      verticalAlign: "middle",
      lineHeight: 0,
      boxSizing: "border-box"
    }
  };
}
export { commonInlineStyleGroupsCheckbox };