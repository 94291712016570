import { scaleMobile } from "../../scale";
function bannerFooterInlineStyleContainer(_ref) {
  let {
    layout: {
      type,
      dialogBorderRadius
    },
    design,
    footerDesign: {
      inheritBg,
      bg,
      inheritTextAlign,
      textAlign,
      padding,
      fontSize,
      fontColor,
      fontWeight
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  const useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, design.borderWidth);
  const style = {
    padding: scaleMobile(mobile, pageRequestUuid4, padding, undefined, true).map(p => `${p}px`).join(" "),
    background: inheritBg ? design.bg : bg,
    borderRadius: useType === "dialog" ? `0 0 ${dialogBorderRadius}px ${dialogBorderRadius}px` : undefined,
    fontSize: scaleMobile(mobile, pageRequestUuid4, +fontSize),
    fontWeight,
    color: fontColor,
    textAlign: inheritTextAlign ? design.textAlign : textAlign,
    position: "sticky",
    bottom: 0,
    zIndex: 1
  };
  if (useType === "dialog" && useBorderWidth > 0) {
    style.borderBottom = `${useBorderWidth}px solid ${design.borderColor}`;
    style.borderLeft = style.borderBottom;
    style.borderRight = style.borderBottom;
  }
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-footer-container`,
    style
  };
}
export { bannerFooterInlineStyleContainer };