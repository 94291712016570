import { bannerButtonsInlineStyleCommon } from ".";
function bannerButtonsInlineStyleAcceptAll(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  let {
    decision: {
      acceptAll
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptAllFontSize,
      acceptAllBg,
      acceptAllTextAlign,
      acceptAllBorderColor,
      acceptAllPadding,
      acceptAllBorderWidth,
      acceptAllFontColor,
      acceptAllFontWeight,
      acceptAllHoverBg,
      acceptAllHoverFontColor,
      acceptAllHoverBorderColor
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  return bannerButtonsInlineStyleCommon({
    name: "accept-all",
    order,
    type: acceptAll,
    borderRadius,
    bg: acceptAllBg,
    hoverBg: acceptAllHoverBg,
    fontSize: acceptAllFontSize,
    textAlign: acceptAllTextAlign,
    fontColor: acceptAllFontColor,
    fontWeight: acceptAllFontWeight,
    hoverFontColor: acceptAllHoverFontColor,
    borderWidth: acceptAllBorderWidth,
    borderColor: acceptAllBorderColor,
    hoverBorderColor: acceptAllHoverBorderColor,
    padding: acceptAllPadding,
    antiAdBlocker,
    pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}
export { bannerButtonsInlineStyleAcceptAll };