import { scaleMobile } from "../../scale";
function bannerInlineStyleBodyDescription(_ref) {
  let {
    design: {
      fontSize
    },
    bodyDesign: {
      descriptionInheritFontSize,
      descriptionFontSize
    },
    individualLayout: {
      descriptionTextAlign
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    individualPrivacyOpen,
    pageRequestUuid4
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-description`,
    style: {
      lineHeight: 1.5,
      fontSize: scaleMobile(mobile, pageRequestUuid4, descriptionInheritFontSize ? +fontSize : +descriptionFontSize),
      textAlign: individualPrivacyOpen ? descriptionTextAlign : undefined
    }
  };
}
export { bannerInlineStyleBodyDescription };