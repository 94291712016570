import { putHtmlCodeToDom } from "@devowl-wp/headless-content-unblocker";
import { getServiceSelection } from "../decision";

/**
 * Opt-in and opt-out to a set of services for a given data manager like GTM / MTM.
 */
async function applyOptInOutManager(options, dataLayer, isManagerOptOut) {
  const waitPromises = [];

  /* onlypro:start */
  const {
    isManagerActive,
    iterateServices
  } = getServiceSelection(options);

  // When there is a manager, let's push all opt-in and opt-out events (parallel)
  if (dataLayer) {
    // Calculate variables for data layer
    const realCookieBannerConsents = {};
    const realCookieBannerOptInEvents = {};
    const realCookieBannerOptOutEvents = {};
    await iterateServices((group, service, isOptIn, _ref) => {
      let {
        serviceIsManager
      } = _ref;
      if (!serviceIsManager) {
        const {
          tagManagerOptInEventName,
          tagManagerOptOutEventName,
          uniqueName
        } = service;
        if (tagManagerOptInEventName) {
          realCookieBannerOptInEvents[tagManagerOptInEventName] = isOptIn;
        }
        if (tagManagerOptOutEventName) {
          realCookieBannerOptOutEvents[tagManagerOptOutEventName] = !isOptIn;
        }
        if (uniqueName) {
          realCookieBannerConsents[uniqueName] = isOptIn;
        }
      }
    });
    Object.assign(realCookieBannerConsents, realCookieBannerOptInEvents);
    Object.assign(realCookieBannerConsents, realCookieBannerOptOutEvents);
    dataLayer.push({
      realCookieBannerConsents,
      realCookieBannerOptInEvents,
      realCookieBannerOptOutEvents
    });

    // Opt-in events
    iterateServices((group, _ref2, isOptIn, _ref3) => {
      let {
        tagManagerOptInEventName: event
      } = _ref2;
      let {
        serviceIsManager
      } = _ref3;
      if (isOptIn && event && !serviceIsManager) {
        dataLayer.push({
          event
        });
      }
    });

    // Wait x seconds and apply opt-out events (should we add this to `waitPromises`?! I do not think so)
    setTimeout(() => iterateServices((group, _ref4, isOptIn, _ref5) => {
      let {
        tagManagerOptOutEventName: event
      } = _ref4;
      let {
        serviceIsManager
      } = _ref5;
      if (!isOptIn && event && !serviceIsManager) {
        dataLayer.push({
          event
        });
      }
    }), 1000);
  } else if (isManagerActive && isManagerOptOut) {
    // The manager got opt out, let's execute scripts that are explicitly only for this case (sequentially)
    await iterateServices(async (group, _ref6, isOptIn) => {
      let {
        codeDynamics,
        codeOptIn,
        executeCodeOptInWhenNoTagManagerConsentIsGiven
      } = _ref6;
      if (isOptIn && executeCodeOptInWhenNoTagManagerConsentIsGiven) {
        waitPromises.push(putHtmlCodeToDom(codeOptIn, codeDynamics));
      }
    });
  }
  /* onlypro:false */

  return {
    ready: Promise.all(waitPromises)
  };
}
export { applyOptInOutManager };