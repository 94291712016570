import { scaleMobile } from "../..";
function bannerHeaderInlineStyleContainer(_ref) {
  let {
    layout: {
      type,
      dialogBorderRadius
    },
    design: {
      borderWidth,
      borderColor,
      ...restDesign
    },
    headerDesign: {
      inheritBg,
      bg,
      padding
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  const useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, borderWidth);
  const style = {
    padding: scaleMobile(mobile, pageRequestUuid4, padding, undefined, true).map(p => `${p}px`).join(" "),
    background: inheritBg ? restDesign.bg : bg,
    borderRadius: useType === "dialog" ? `${dialogBorderRadius}px ${dialogBorderRadius}px 0 0` : undefined,
    position: "sticky",
    zIndex: 9,
    top: 0
  };
  if (useType === "dialog" && useBorderWidth > 0) {
    style.borderTop = `${useBorderWidth}px solid ${borderColor}`;
    style.borderLeft = style.borderTop;
    style.borderRight = style.borderTop;
  }
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-header-container`,
    style
  };
}
export { bannerHeaderInlineStyleContainer };