function bannerInlineStyleHistorySelectOption(_ref) {
  let {
    design: {
      bg
    }
  } = _ref;
  return {
    className: undefined,
    style: {
      background: bg
    }
  };
}
export { bannerInlineStyleHistorySelectOption };