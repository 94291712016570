import { scaleMobile } from "../..";
function commonInlineStyleHeaderHeadline(_ref, hasCloseIcon) {
  let {
    headerDesign: {
      fontSize,
      fontColor,
      fontInheritFamily,
      fontFamily,
      fontWeight
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    // only used for banner
    pageRequestUuid4
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-headline`,
    style: {
      color: fontColor,
      lineHeight: 1.8,
      fontSize: pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +fontSize) : +fontSize,
      fontFamily: fontInheritFamily ? undefined : fontFamily,
      fontWeight,
      paddingRight: hasCloseIcon ? 20 : undefined
    }
  };
}
export { commonInlineStyleHeaderHeadline };