import _extends from "@babel/runtime/helpers/extends";
import { useState, useLayoutEffect, useEffect } from "react";
import { dispatchResizeEvent } from "@devowl-wp/headless-content-unblocker";
import { useBanner, useBannerActionLinks, useBannerHistory, usePlainCss, useRestrictScrollWhenVisible, bannerOverlayInlineStyle, bannerOverlayInlineStyleAlignment, BannerAnimatedContent, isHiddenDueLegal } from "../..";
const RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS = 0;
const Banner = () => {
  const banner = useBanner();
  const {
    recorder,
    customCss: {
      antiAdBlocker,
      css
    },
    visible,
    animationVisible,
    activeAction,
    skipOverlay,
    gotHiddenDueLegal,
    pageRequestUuid4,
    individualPrivacyOpen,
    consent
  } = banner;
  const hiddenDueLegal = isHiddenDueLegal(banner);
  const show = visible && !hiddenDueLegal || !!activeAction;
  usePlainCss(antiAdBlocker ? css : "");
  usePlainCss(
  // `-webkit-text-size-adjust:100%;`: https://stackoverflow.com/a/2711132/5506547
  `#${pageRequestUuid4}, #${pageRequestUuid4} * {box-sizing: border-box;backface-visibility:initial;text-transform:initial;-webkit-text-size-adjust:100%;}`);
  useRestrictScrollWhenVisible(show);

  // Listen to window resizes and resize the content automatically
  const [, setInvalidate] = useState(0);
  const updateSize = () => setInvalidate(+new Date());
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useLayoutEffect(() => {
    dispatchResizeEvent(1000);
  }, [animationVisible, individualPrivacyOpen]);

  // When changes to the legal settings are done, make the banner visible or hide
  gotHiddenDueLegal && useEffect(() => {
    gotHiddenDueLegal(hiddenDueLegal);
  }, [hiddenDueLegal]);
  useBannerHistory();
  useBannerActionLinks();

  // Automatically restart recorder when banner got visible again (e.g. cookie banner on first website
  // visit and then "Change privacy settings" link / dialog).
  useEffect(() => {
    if (show && animationVisible && recorder) {
      recorder.restart();
    }
  }, [show, animationVisible, recorder]);
  useEffect(() => {
    if (recorder) {
      recorder.getRecords().addCustom(RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS, consent.groups);
    }
  }, [recorder, consent]);
  if (!show) {
    return null;
  }
  const content = h("div", bannerOverlayInlineStyleAlignment(banner), h(BannerAnimatedContent, null));
  return skipOverlay ? content : h("div", _extends({
    id: pageRequestUuid4
  }, bannerOverlayInlineStyle(banner)), content);
};
export { Banner, RECORD_ITEM_TYPE_CUSTOM_CONSENT_GROUPS };