/**
 * Checks if the given banner is not visible due to legal settings.
 */
function isHiddenDueLegal(banner) {
  const {
    legal: {
      imprint,
      imprintHide,
      imprintIsExternalUrl,
      privacyPolicy,
      privacyPolicyHide,
      privacyPolicyIsExternalUrl
    },
    pageId
  } = banner;
  return [imprintHide && !imprintIsExternalUrl && +imprint, privacyPolicyHide && !privacyPolicyIsExternalUrl && +privacyPolicy].filter(Boolean).indexOf(pageId) > -1;
}
export { isHiddenDueLegal };