import { consent, consentAll, consentSync, unblock, wrapFn } from ".";
/**
 * Create the API functions and make them available through `window.consentApi`.
 *
 * There is also a [stub](https://shorturl.at/bcgyz) implementation:
 *
 * ```js
 * ((a,b)=>{a[b]||(a[b]={},["consentSync"].forEach(c=>a[b][c]=()=>({cookie:null,consentGiven:!1,cookieOptIn:!0})),["consent","consentAll","unblock"].forEach(c=>a[b][c]=(...d)=>new Promise(e=>a.addEventListener(b,()=>{a[b][c](...d).then(e)},{once:!0}))))})(window,"consentApi");
 * ```
 */
function createApi(manager, blocker) {
  const apiConsent = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return consent(manager, ...args);
  };
  const apiConsentAll = function () {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }
    return consentAll(manager, ...args);
  };
  const apiConsentSync = function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    return consentSync(manager, ...args);
  };
  const apiConsentUnblock = function () {
    for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      args[_key4] = arguments[_key4];
    }
    return unblock(blocker, ...args);
  };
  const wrapApis = {
    consent: apiConsent,
    consentAll: apiConsentAll,
    consentSync: apiConsentSync,
    unblock: apiConsentUnblock
  };
  const apiWrapFn = function () {
    for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      args[_key5] = arguments[_key5];
    }
    return wrapFn(wrapApis, blocker, manager, ...args);
  };
  const api = {
    ...wrapApis,
    wrapFn: apiWrapFn
  };
  window.consentApi = api;

  // Dispatch event so e.g. stubs can react to it and forward functions
  window.dispatchEvent(new CustomEvent("consentApi"));
  return api;
}
export { createApi };