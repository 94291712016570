import _extends from "@babel/runtime/helpers/extends";
import { scaleMobile, useBanner, usePlainCss, useRenderedHeightsAsCssVars, bannerContentInlineStyle, bannerFooterInlineStyleSeparator, bannerHeaderInlineStyleSeparator, BannerHeader, BannerFooter, BannerBody } from "../../..";
import { Fragment, useState, useRef, useLayoutEffect, useCallback } from "react";
const BannerContent = () => {
  const banner = useBanner();
  const {
    layout: {
      type,
      dialogBorderRadius
    },
    decision: {
      acceptAll,
      acceptEssentials,
      showCloseIcon
    },
    mobile,
    individualPrivacyOpen,
    design: {
      bg
    },
    bodyDesign: {
      teachingsFontColor,
      acceptEssentialsUseAcceptAll
    },
    activeAction,
    pageRequestUuid4
  } = banner;
  const rightSideId = `bnnr-body-rightSide-${pageRequestUuid4}`;
  const id = `cntnt-${pageRequestUuid4}`;
  const ref = useRef();
  const {
    current: currentRef
  } = ref;
  const useAcceptEssentials = acceptEssentialsUseAcceptAll && acceptAll === acceptEssentials ? acceptAll : acceptEssentials;
  let showHeader = true;
  if (mobile.hideHeader && !activeAction && !individualPrivacyOpen && (useAcceptEssentials === "hide" ? !showCloseIcon : true)) {
    showHeader = scaleMobile(mobile, pageRequestUuid4, showHeader, false);
  }

  // Enable box shadow effect for the scrolling behavior
  const [showScrollBoxShadow, setShowScrollBoxShadow] = useState(false);
  const updateShowScrollBoxShadow = useCallback(() => {
    if (currentRef) {
      const {
        clientHeight,
        scrollHeight,
        scrollTop,
        offsetHeight
      } = currentRef;
      const hasScrollbar = clientHeight < scrollHeight;
      const scrolledToBottom = Math.ceil(scrollTop + offsetHeight) >= scrollHeight;
      const result = hasScrollbar && !scrolledToBottom && !individualPrivacyOpen;
      if (showScrollBoxShadow !== result) {
        setShowScrollBoxShadow(result);
      }
    }
  }, [currentRef, individualPrivacyOpen, showScrollBoxShadow]);
  updateShowScrollBoxShadow(); // The function gets called for each resize in `Banner` component
  useLayoutEffect(() => {
    if (currentRef) {
      currentRef.addEventListener("scroll", updateShowScrollBoxShadow);
      return () => currentRef.removeEventListener("scroll", updateShowScrollBoxShadow);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [currentRef, updateShowScrollBoxShadow]);
  usePlainCss(showScrollBoxShadow ? `#${rightSideId} {box-shadow:var(--boxShadowShowScroll);}` : "");

  // Create custom scrollbar when a border radius is active (https://css-tricks.com/the-current-state-of-styling-scrollbars/)
  usePlainCss(navigator.platform === "Win32" && type === "dialog" && dialogBorderRadius > 0 ? [`#${id}{overflow:overlay!important;}`, `#${id}::-webkit-scrollbar{width:11px;}`, `#${id}{scrollbar-width:thin;scrollbar-color:${teachingsFontColor} transparent;}`, `#${id}::-webkit-scrollbar-track{background:transparent;}`, `#${id}::-webkit-scrollbar-thumb{background-color:${teachingsFontColor};border-radius:${dialogBorderRadius}px;border:3px solid ${bg};}`, `#${id}>div{border-top-right-radius:0!important;border-bottom-right-radius:0!important;}`].join("\n") : "");
  const headerRef = useRef();
  const footerRef = useRef();
  const rightSideContainerRef = useRef();
  useRenderedHeightsAsCssVars(headerRef.current, "header");
  useRenderedHeightsAsCssVars(footerRef.current, "footer");
  useRenderedHeightsAsCssVars(rightSideContainerRef.current, "rightSideContainer");
  return h("div", _extends({}, bannerContentInlineStyle(banner), {
    ref: ref,
    id: id
  }), showHeader && h(Fragment, null, h(BannerHeader, {
    ref: headerRef
  }), h("div", bannerHeaderInlineStyleSeparator(banner))), h(BannerBody, {
    rightSideContainerRef: rightSideContainerRef
  }), h("div", bannerFooterInlineStyleSeparator(banner)), h(BannerFooter, {
    ref: footerRef
  }));
};
export { BannerContent };