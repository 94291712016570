import _extends from "@babel/runtime/helpers/extends";
import { useBannerOrBlocker } from "../../../hooks";
import { commonInlineStyleGroupsCookie } from "../../..";
import { CommonCheckbox } from "../..";
import { CookiePropertyList } from ".";
const Cookie = _ref => {
  let {
    cookie,
    checked,
    disabled,
    onToggleCheck,
    propertyListProps = {}
  } = _ref;
  const {
    name
  } = cookie;
  const bannerOrBlocker = useBannerOrBlocker();
  const {
    group: {
      descriptionFontSize
    }
  } = bannerOrBlocker;
  return h("div", commonInlineStyleGroupsCookie(bannerOrBlocker), h("div", {
    style: {
      marginBottom: 10
    }
  }, h(CommonCheckbox, {
    isChecked: checked,
    isDisabled: disabled,
    fontSize: descriptionFontSize,
    onClick: onToggleCheck
  }, h("strong", null, name))), h(CookiePropertyList, _extends({
    cookie: cookie
  }, propertyListProps)));
};
export { Cookie };