import _extends from "@babel/runtime/helpers/extends";
import { isUrl } from "@devowl-wp/headless-content-unblocker";
import { useBannerOrBlocker } from "../../../hooks";
import { commonGroupsInlineStyleLinkMore, commonInlineStyleGroupsCookieProperty } from "../../../inlineStyles";
const CookieProperty = _ref => {
  let {
    label,
    value,
    children,
    printValueAs
  } = _ref;
  const banner = useBannerOrBlocker();
  const {
    i18n: {
      yes,
      no
    }
  } = banner;
  const isValueUrl = typeof value === "string" && isUrl(value);
  let useValue = isValueUrl ? h("a", {
    href: value,
    style: {
      wordBreak: "break-all",
      ...commonGroupsInlineStyleLinkMore(banner, false).style
    },
    target: "_blank",
    rel: "noopener noreferrer"
  }, value) : typeof value === "string" ? h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;
  if (printValueAs === "boolean") {
    useValue = useValue ? yes : no;
  }
  return h("div", _extends({
    key: label
  }, commonInlineStyleGroupsCookieProperty(banner)), label && h("strong", null, label, ":\xA0"), useValue, !!children && h("div", null, children));
};
export { CookieProperty };