import { scaleMobile, useBanner, bannerGroupsInlineStyle, BannerGroup } from "../../..";
import { Fragment } from "react";
const BannerGroupList = () => {
  const banner = useBanner();
  const {
    groups,
    group: {
      groupSpacing
    },
    mobile,
    pageRequestUuid4
  } = banner;
  const useGroups = groups.filter(_ref => {
    let {
      items
    } = _ref;
    return items.length;
  });
  return h("div", bannerGroupsInlineStyle(banner), useGroups.map((group, index) => h(Fragment, {
    key: group.id
  }, h(BannerGroup, {
    key: group.id,
    group: group
  }), index !== useGroups.length - 1 && h("div", {
    style: {
      height: scaleMobile(mobile, pageRequestUuid4, +groupSpacing)
    }
  }))));
};
export { BannerGroupList };