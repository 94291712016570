import { scaleMobile } from "../../scale";
function bannerInlineStyleBodyContainer(_ref) {
  let {
    layout: {
      type
    },
    design: {
      bg,
      borderWidth,
      borderColor
    },
    bodyDesign: {
      padding
    },
    customCss: {
      antiAdBlocker
    },
    mobile,
    pageRequestUuid4
  } = _ref;
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, borderWidth);
  const style = {
    background: bg,
    // Top / Bottom padding is applied in leftSide and rightSide containers
    padding: scaleMobile(mobile, pageRequestUuid4, padding).map((p, i) => `${[0, 2].indexOf(i) > -1 ? 0 : p}px`).join(" "),
    lineHeight: 1.4
  };
  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog" && useBorderWidth > 0) {
    style.borderLeft = `${useBorderWidth}px solid ${borderColor}`;
    style.borderRight = style.borderLeft;
  }
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-body-container`,
    style
  };
}
export { bannerInlineStyleBodyContainer };