import { useEffect } from "react";
import { useBanner } from "..";

/**
 * Do not allow scrolling when this banner is visible and add a blur effect if enabled.
 */
function useRestrictScrollWhenVisible(show) {
  const banner = useBanner();
  const {
    layout: {
      overlay
    },
    animationVisible
  } = banner;
  const showOverlay = show && overlay && animationVisible;
  return useEffect(() => {
    const {
      dataset,
      style
    } = document.body;

    // Save first state
    if (dataset.rcbPreviousOverflow === undefined) {
      dataset.rcbPreviousOverflow = style.overflow;
    }
    style.overflow = showOverlay ? "hidden" : dataset.rcbPreviousOverflow;
    document.body.parentElement.style.overflow = style.overflow;
  }, [showOverlay]);
}
export { useRestrictScrollWhenVisible };