import _extends from "@babel/runtime/helpers/extends";
import { bannerContentInlineStyleInner, useBanner, useBannerAnimation, useResettableAnimation } from "../../..";
import { AnimatedCss } from "../..";
import { BannerContent } from ".";
const BannerAnimatedContent = () => {
  const banner = useBanner();
  const {
    layout: {
      animationInDuration,
      animationOutDuration
    },
    animationVisible
  } = banner;
  const {
    useAnimationIn,
    useAnimationOut
  } = useBannerAnimation()(banner.layout);
  const [stateAnimationIn, stateAnimationInDuration] = useResettableAnimation(useAnimationIn, animationInDuration);
  const [stateAnimationOut, stateAnimationOutDuration] = useResettableAnimation(useAnimationOut === "none" ? "fadeOut" : useAnimationOut, useAnimationOut === "none" ? 0 : animationOutDuration);
  return h(AnimatedCss, _extends({
    animationIn: stateAnimationIn,
    animationInDuration: stateAnimationInDuration,
    animationOut: stateAnimationOut,
    animationOutDuration: stateAnimationOutDuration,
    isVisible: animationVisible
  }, bannerContentInlineStyleInner(banner)), h(BannerContent, null));
};
export { BannerAnimatedContent };