function bannerGroupsInlineStyle(_ref) {
  let {
    customCss: {
      antiAdBlocker
    }
  } = _ref;
  return {
    className: antiAdBlocker === "y" ? undefined : `rcb-groups`,
    style: {
      marginTop: 10,
      clear: "both"
    }
  };
}
export { bannerGroupsInlineStyle };